/*
 * @Author: 罗侠 luoxia@supos.com
 * @Date: 2021-03-08 11:00:45
 * @LastEditors: 罗侠 luoxia@supos.com
 * @LastEditTime: 2023-04-04 10:09:08
 * @FilePath: \cloud-console-ssr-frontend\src\pages\_app.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import App from 'next/app';
import React from 'react';
import store from '../store/index';
import { message } from 'antd'
import { Provider } from 'mobx-react';
import {getCurrentInfo} from '../service'
import '../styles.css';

class MyMobxApp extends App {
  state = {
    store,
  };

  componentDidMount() {
    console.log('window',window);
    getCurrentInfo().then(({data})=>{
      console.log('dat11a',data);
      const toUrl = window.DOMAIN_CONFIG?.user || 'https://cloud.supos.com/home'
      const d = data.currentCompanyInfo
      if( d && d.roleType != 0){
        message.error('您暂无跳转后页面访问权限');
        setTimeout(() => {
          window.location.href = toUrl
        }, 300);
      }

    })
  }

  // fetch Init state
  static async getInitialProps(appContext) {
    try {
      const appProps = await App.getInitialProps(appContext);
      let pageModelNames = appContext.ctx.pageModelNames;

      const { pageProps } = appProps;
      // 兼容单个值
      if (typeof pageModelNames === 'string') {
        pageModelNames = [pageModelNames];
      }
      let initialStoreState = pageModelNames ? pageProps : {};

      // console.log(Object.keys(pageProps));
      const plainPageProps = Object.keys(pageProps).reduce((result, next) => {
        return { ...result, ...pageProps[next] };
      }, {});
      // console.log(plainPageProps);
      return {
        pageProps: plainPageProps,
        initialStoreState,
      };
    } catch (err) {
      // This will work on both client and server sides.
      console.log(
        'The Error happened in: ',
        typeof window === 'undefined' ? 'Server' : 'Client'
      );
      return {};
    }
  }

  // Hydrate serialized state to store
  static getDerivedStateFromProps(props, state) {
    if (props.pageProps.staticProps) {
      Object.keys(props.pageProps.staticProps).forEach((item) => {
        state.store[item] &&
          state.store[item].commit(props.pageProps.staticProps[item]);
      });
    }

    return state;
  }

  render() {
    const { Component, pageProps } = this.props;
    // console.log(pageProps);
    return (
      <Provider {...store}>
        <Component {...pageProps} />
      </Provider>
    );
  }
}
export default MyMobxApp;
